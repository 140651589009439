import React, { useEffect, useState } from "react"
import { Row, Container } from "react-bootstrap"
import { makeLink } from "../utils/google-pic-format"
import InnerWrap from "../components/_Wrap/inner-wrap"
import Hero, { FluidHero } from "../components/Hero"
import Gallery from "../components/Gallery"
import WithPicture from "../components/Paragraph/WithPicture"
import SEO from "../components/seo"
import CTA from "../components/CTA"
import GreyCTA from "../components/CTA/grey-cta"

const WaterJetServices = ({ data }) => {
  const { content, header } = data

  const [waterJetPics, setWaterJetPics] = useState([{}])

  useEffect(() => {
    let awardArray = []
    content.edges.forEach(con => {
      awardArray.push({
        src: makeLink(con.node.waterjetservicesPicture),
        title: con.node.waterjetservicesPictureTitle,
        description: con.node.waterjetservicesPictureDescription,
        index: content.edges.findIndex(
          c =>
            c.node.waterjetservicesPicture === con.node.waterjetservicesPicture
        ),
      })
    })
    setWaterJetPics(awardArray)
  }, [content.edges])

  return (
    <React.Fragment>
      <SEO
        title="Brazee Waterjet Services"
        keywords={[`custom waterjet`, `DIY`, `makers`, `makers studio`]}
      />
      {/* <Hero src={makeLink(header.edges[0].node.headerPicture)} />
       */}
      <FluidHero
        name={`${encodeURIComponent(
          header.edges[0].node.headerPictureTitle
        )}.png`}
      ></FluidHero>
      <Container fluid>
        <Row>
          <InnerWrap>
            <WithPicture
              title={header.edges[0].node.sitetitle}
              src={makeLink(header.edges[0].node.image)}
              subheading="Honor your recipients with original, hand-made glass."
            >
              {header.edges.map(head =>
                head.node.contentheader ? (
                  <>
                    <h4 className="grey">{head.node.contentheader}</h4>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: head.node.content.replace(/\n/g, "<br / >"),
                      }}
                    ></p>
                    <br />
                  </>
                ) : (
                  ""
                )
              )}
            </WithPicture>
          </InnerWrap>
        </Row>

        <Row>
          <GreyCTA>
            <h4>Project Examples </h4>
          </GreyCTA>
        </Row>
        <Row>
          <Gallery data={waterJetPics} />
        </Row>
        <Row>
          <CTA />
        </Row>
      </Container>
      <div style={{ height: "50px", backgroundColor: "white" }}></div>
    </React.Fragment>
  )
}
export const WaterJetQuery = graphql`
  query MyQuery {
    header: allGoogleSheetWaterJetServicesRow {
      edges {
        node {
          headerPicture
          headerPictureTitle
          contentheader
          content
          sitetitle
          image
        }
      }
    }
    content: allGoogleSheetWaterJetServicesRow {
      edges {
        node {
          waterjetservicesPicture
          waterjetservicesPictureDescription
          waterjetservicesPictureTitle
        }
      }
    }
  }
`
export default WaterJetServices
