import React, { useRef } from "react"

import { useLightbox } from "simple-react-lightbox"
import { ignoreNA } from "../../utils/helpers"
const SingleImage = ({ title, description, src, index }) => {
  if (!title) {
    title = ""
  }
  if (!description || description === null) {
    description = "N/A"
  }
  const { openLightbox } = useLightbox()

  const imageRef = useRef()

  return (
    <div
      className="img-box"
      ref={imageRef}
      onClick={() => {
        openLightbox(index)
      }}
      onKeyUp={e => {
        if (e.key === "Enter") {
          openLightbox(index)
        }
      }}
    >
      <img
        src={src}
        alt={`${title} ${
          description.toUpperCase() === "N/A" ? "" : "| " + description
        }`}
        caption={ignoreNA(description)}
      />
      <div className="transparent-box">
        <div className="caption">
          <p>{title}</p>
          <p className="opacity-low">{ignoreNA(description)}</p>
        </div>
      </div>
    </div>
  )
}

SingleImage.propTypes = {}

export default SingleImage
